<template>
<v-flex lg10 md10 sm12 xs12>
  <v-card>
    <v-toolbar color="secondary" class="subtitle white--text">
        {{titulo}}
    </v-toolbar>
    <v-card-text class="px-0">
      <!-- <v-alert v-if="abogado.id" type="info" :value="true" class="mx-2" style="position: sticky;top: 60px;z-index:2" :dismissible="$vuetify.breakpoint.smAndDown">
        Se encontro un PRE-REGISTRO iniciado por la entidad que emitio su Titulo Profesional no es necesario la certificacion, por favor complemente la informacion
      </v-alert> -->
      <v-stepper v-model="paso" vertical class="elevation-0">
        <v-stepper-step :complete="paso>0" step="0">
          REQUISITOS PARA LA SOLICITUD
        </v-stepper-step>
        <v-stepper-content step="0" class="py-0 pl-0">
            <v-card class="elevation-0">
                <v-card-text class="pa-0">
                    <v-list two-line>
                        <v-list-item v-for="(requisito,index) in requisitos" :key="index">
                            <v-list-item-avatar>
                                <v-icon
                                    color="secondary"
                                    v-if="requisito.icon!=null"
                                    class="grey lighten-1"
                                    dark
                                >{{requisito.icon}}         
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="requisito.titulo"> 
                                </v-list-item-title>
                                <v-list-item-subtitle v-html="requisito.subtitulo">
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>        
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="cancelarRegistro">
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="paso=1"
                    >
                        Aceptar y Continuar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-stepper-content>

        <v-stepper-step :complete="paso>1" step="1" >
          FORMULARIO GENERAL
        </v-stepper-step>
        <v-stepper-content step="1" class="py-0 auto-height" >
           <v-card class="elevation-0">
              <v-card-text class="pa-0">
                  <v-form v-model="valid" ref="formularioGeneral" lazy-validation>
                      <v-row no-gutters>
                          <v-col
                              cols="12"
                              lg="2"
                              md="2"
                              xs="12"
                              class="px-1"
                          > 
                            <v-select
                                  filled
                                  class="required"
                                  name="mencion"
                                  :items="['MASCULINO','FEMENINO']"
                                  v-model="abogado.genero"
                                  label="Género"
                                  dense
                                  :rules="getRules('requiredSelect')"
                              ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            lg="3"
                            md="3"
                            xs="12"
                            class="px-1"
                          >  <v-select
                                filled
                                class="required"
                                name="departamento"
                                :items="$store.state.session.parametros.departamentos"
                                v-model="abogado.lugarRegistro"
                                label="Departamento Registro"
                                dense
                                :rules="getRules('requiredSelect')"
                              ></v-select>
                          </v-col>
                          <v-col
                            v-if="!abogado.id"
                            cols="12"
                            lg="4"
                            md="4"
                            xs="12"
                            class="px-1"
                          >  <v-autocomplete
                                class="required"
                                name="departamento"
                                filled
                                :items="$store.state.session.parametros.universidades"
                                v-model="abogado.universidadLicenciatura"
                                label="Universidad Licenciatura"
                                placeholder="Ingresa las siglas de tu Universidad"
                                dense
                                :rules="getRules('requiredSelect')"
                              ></v-autocomplete>
                          </v-col>  
                          <v-col
                              cols="6"
                              lg="3"
                              md="3"
                              xs="12"
                              class="px-1"
                          > 
                            <v-select
                              filled
                              class="required"
                              name="mencion"
                              :items="tipoMencion"
                              v-model="abogado.mencion"
                              label="Área Egreso"
                              dense
                              :rules="getRules('requiredSelect')"
                            />
                          </v-col>
                          
                          <v-col
                              cols="12"
                              lg="6"
                              md="6"
                              xs="12"
                              class="text-center px-1"
                          >
                              <!-- <v-file-input
                                  filled
                                  class="required pt-0 mt-0"
                                  v-model="abogado.fotoAbogado"
                                  :rules="getRules('required foto')"
                                  accept="image/jpeg"
                                  prepend-icon="mdi-camera"
                                  label="Fotografía (3x3)"
                                  show-size
                                  @change="onFileChangeFoto"
                                  counter
                              >
                                  <template v-slot:selection="{ text }">
                                      <v-chip
                                          color="primary accent-4"
                                          dark
                                          label
                                          small
                                      >
                                          {{ text }}
                                      </v-chip>
                                  </template>
                              </v-file-input>
                              <img  v-if="urlFoto" :src="urlFoto" width="150" height="150" /> -->
                              <UploadFoto @change="changeFoto"/>
                          </v-col>
                          <v-col
                              cols="12"
                              lg="6"
                              md="6"
                              xs="12"
                              class="text-center px-1"
                          >
                              <!-- <v-file-input
                                  filled
                                  class="required pt-0 mt-0"
                                  v-model="abogado.firmaAbogado"
                                  :rules="getRules('required foto')"
                                  accept="image/jpeg"
                                  prepend-icon="mdi-camera"
                                  label="Firma (3x6)"
                                  show-size
                                  @change="onFileChangeFirma"
                                  counter
                              >
                                  <template v-slot:selection="{ text }">
                                      <v-chip
                                          color="primary accent-4"
                                          dark
                                          label
                                          small
                                      >
                                          {{ text }}
                                      </v-chip>
                                  </template>
                              </v-file-input>
                              <img v-if="urlFirma" :src="urlFirma" width="300" height="150" /> -->
                              <UploadFoto label="Firma (3x6)" tipo="firma" @change="changeFirma"/>
                          </v-col>
                      </v-row>
                      <datos-contacto />
                  </v-form>
              </v-card-text>
              <v-card-actions class="pt-0">
                 <v-spacer></v-spacer>
                  <v-btn text color="error" @click="consulta=true">
                      Cancelar
                  </v-btn>
                      <v-btn
                      color="primary"
                      @click="validarFormularioGeneral()"
                  >
                      siguiente
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step :complete="paso>2" step="2">
          FORMULARIO DE DIRECCIÓN(ES)
        </v-stepper-step>
        <v-stepper-content step="2" class="py-0">
          <v-card elevation="0">
            <v-card-text class="pa-0">
              <v-form ref="formularioDirecciones" v-model="validDirecciones">
                <v-row no-gutters>
                    <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="px-3">
                      <v-row no-gutters>
                        <v-col cols="12" class="text-subtitle-2 font-weight-bold">1.- DOMICILIO ACTUAL</v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="6" class="px-1"> 
                          <v-select
                            filled
                            class="required"
                            name="departamento"
                            :items="$store.state.session.parametros.departamentos"
                            v-model="domicilio.departamento"
                            label="Departamento"
                            :rules="getRules('requiredSelect')"
                            @change="getMunicipios(domicilio.departamento,'domicilio')"
                          />
                        </v-col>
                        <v-col cols="6" class="px-1"> 
                          <v-autocomplete
                            filled
                            item-value="id"
                            item-text="nombre"
                            class="required"
                            name="municipio"
                            :items="municipiosDomicilo"
                            v-model="domicilio.municipio"
                            label="Municipio"
                            :rules="getRules('requiredSelect')"
                            @change="cambiarPosicionMapa(domicilio.municipio,'domicilio')"
                            return-object
                          />
                        </v-col>
                        <v-col cols="12" class="px-1">  
                          <v-textarea
                            filled
                            v-model="domicilio.direccion"
                            class="required"
                            label="Dirección"
                            :rules="getRules('required')"
                            hint="Zona / Calle"
                            placeholder="Zona / Calle"
                            persistent-hint
                            rows="1"
                            auto-grow
                          />
                        </v-col>
                        <v-col cols="6" class="px-1">  
                          <v-text-field
                            filled
                            class="required"
                            v-model="domicilio.nro"
                            label="Nro"
                            :rules="getRules('required')"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                            class="px-1"
                        >  <v-text-field
                            filled
                            class="required"
                            v-model="domicilio.telefono"
                            label="Teléfono"
                            :rules="getRules('required')"
                          ></v-text-field>
                        </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                            <Mapa
                              id="mapaDomicilio"
                              :latitud.sync="domicilio.latitud"
                              :longitud.sync="domicilio.longitud"
                            ></Mapa>
                        </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="text-subtitle-2 font-weight-bold">2.- DOMICIO PROCESAL O PROFESIONAL</v-col>
                </v-row>
                <v-row v-if="tieneOficina" no-gutters>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12" class="px-3">
                    <v-row no-gutters>
                    <v-col cols="6">
                        <v-select
                            filled
                            class="required"
                            name="departamento"
                            :items="$store.state.session.parametros.departamentos"
                            v-model="oficina.departamento"
                            label="Departamento"
                            :rules="getRules('requiredSelect')"
                            @change="getMunicipios(oficina.departamento,'oficina')"
                        />
                    </v-col>
                    <v-col cols="6" class="px-1"> 
                        <v-autocomplete
                          filled
                          item-value="id"
                          item-text="nombre"
                          class="required"
                          name="municipio"
                          :items="municipiosOficina"
                          v-model="oficina.municipio"
                          label="Municipio"
                          :rules="getRules('requiredSelect')"
                          @change="cambiarPosicionMapa(oficina.municipio,'oficina')"
                          return-object
                        />
                    </v-col>
                    <v-col cols="12">  
                      <v-textarea
                            filled
                            v-model="oficina.direccion"
                            class="required"
                            label="Direccion"
                            :rules="getRules('required')"
                            hint="Zona / Calle"
                            placeholder="Zona / Calle"
                            persistent-hint
                            rows="1"
                            auto-grow
                          ></v-textarea>
                    </v-col>
                    <v-col cols="6">  
                      <v-text-field
                        filled
                        class="required"
                        v-model="oficina.nro"
                        label="Nro"
                        :rules="getRules('reqired')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6"  class="px-1">
                      <v-text-field
                        filled
                        class="required"
                        v-model="oficina.telefono"
                        label="Telefono(s)"
                        :rules="getRules('reqired')"
                      ></v-text-field>
                    </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                      <Mapa
                        id="mapaOficina"
                        :latitud.sync="oficina.latitud"
                        :longitud.sync="oficina.longitud"
                      ></Mapa>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="consulta=true">cancelar</v-btn>
               <v-btn color="default" text @click="paso--">atrás</v-btn>
              <v-btn color="primary" @click="validarFormularioDirecciones">siguiente</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-step :complete="paso>3" step="3">
          CARGADO DE DOCUMENTO(S)
        </v-stepper-step>
        <v-stepper-content step="3" class="py-0">
          <v-card>
            <v-card-text class="py-0">
              <v-form ref="formularioDocumento" v-model="validDocumento">
                <v-row no-gutters>
                  <v-col 
                    cols="12"
                    lg="6"
                    md="4"
                    xs="12"
                    class="px-1"
                  >
                    <p>
                      <v-alert type="warning" :value="true" outlined>
                        Debes ingresar tus documentos escaneados en un solo archivo en formato PDF, con el siguiente orden:
                      </v-alert>
                    </p>
                      <ul class="pb-5">
                        <li>
                          Copia de la Cédula de Identidad 
                        </li>
                        <li>
                          Título Profesional (ambas caras)
                        </li>
                        <li>
                          NIT (Opcional)
                        </li>
                        <!-- <li>
                          Copia Simple de Diploma
                        </li>
                        <li>
                          Copia del CI 
                        </li> -->
                      </ul>
                    
                     <v-file-input
                          filled
                          class="required pt-0 mt-0"
                          v-model="documentos"
                          :rules="getRules('required file-abogado')"
                          accept="application/pdf"
                          prepend-icon="mdi-paperclip"
                          label="Documento Escaneado"
                          show-size
                          @change="onFileChangeDocumentos"
                          counter
                          outlined
                      >
                          <template v-slot:selection="{ text }">
                              <v-chip
                                  color="primary accent-4"
                                  dark
                                  label
                                  small
                              >
                                  {{ text }}
                              </v-chip>
                          </template>
                      </v-file-input>
                  </v-col>
                   <v-col 
                    cols="12"
                    lg="6"
                    md="8"
                    xs="12"
                    class="px-1"
                  >
                    <div id="previewDocumentosEscaneados"></div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="consulta=true" :disabled="loading">cancelar</v-btn>
              <v-btn color="default" text @click="paso--" :disabled="loading">atrás</v-btn>
              <v-btn color="primary" @click="enviarSolicitudNota" :loading="loading">SIGUIENTE</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-step :complete="paso>4" step="4">
          FIRMA DIGITAL
        </v-stepper-step>
        <v-stepper-content step="4" class="py-0">
            <v-card flat>
              <v-card-text class="pa-0">
                  <div>Debes Aprobar Digitalmente tu formulario de solicitud de registro</div>
                  <div id="previewDocumentoMergeado" ></div>
              </v-card-text>
              <v-card-actions class="pt-0 mb-5">
                  <v-spacer></v-spacer>
                  <v-btn :disabled="overlay" text color="error" @click="consulta=true">
                          Cancelar
                      </v-btn>
                      <v-btn :disabled="overlay" text color="secondary" @click="paso--">
                          atrás
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="aprobarDocumento"
                        :loading="overlay"
                      >
                          APROBAR y GUARDAR SOLICITUD
                      </v-btn>
                      <!-- <v-btn
                        color="primary"
                        @click="firmarDocumento"
                      >
                          FRIMAR y GUARDAR SOLICITUD
                      </v-btn> -->
              </v-card-actions>
              <v-overlay :value="overlay" :absolute="true" color="primary">
                  <v-progress-circular class="pr-5" indeterminate size="50" color="orange"></v-progress-circular>
                  {{mensaje}}
              </v-overlay>
            </v-card>
        </v-stepper-content>

        <!-- <v-stepper-step :complete="paso>5" step="5">
          CÓDIGO DE PAGO DEL TRAMITE
        </v-stepper-step>
        <v-stepper-content step="5" class="py-0 pl-0">
          <v-card class="elevation-0" >
              <v-card-text  class="pa-0">
                    <codigo-pago :cpt="cpt" :center="true"/>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="$emit('ok')">
                      Aceptar y Cerrar
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-stepper-content> -->

      </v-stepper>
    </v-card-text>
  </v-card>

  <confirmacion
      :pregunta="consulta"
      :consulta="pregunta"
      :loading="loadingConfirmacion"
      @si="aceptacionConfirmacion"
      @no="cancelarConfirmacion"
  />
  
  <!-- <firma-documento 
      v-if="dialogFirma"
      :dialog="dialogFirma"
      :nota="documentoCompleto"
      titulo="Nota Solicitud Emision Nueva Credencial"
      @cerrar="cancelarFirma"
      @documentoFirmado="registroSolicitudNuevoAbogado"
  /> -->
</v-flex>
</template>
<script>
import mixinRegistro from "@/mixins/registro";
import mixin_rules from "@/mixins/rules"
import PDFObject from "@/../paquetes_externos/pdfobject.min";
import mixinB64toBlob from "@/mixins/b64toblob"
import Mapa from "@/components/common/Mapa"

export default {
  mixins: [mixin_rules, mixinB64toBlob,mixinRegistro],
  components:{
       // 'date-field': ()=>import("@/common/util/crud/DateField"),
        'datos-contacto': ()=>import("@/components/DatosContacto"),
        'confirmacion': ()=>import("@/common/util/Confirmacion"),
        UploadFoto:()=>import("@/components/UploadFoto"),
        // 'firma-documento': ()=>import("@/components/FirmaDocumento"),
        //'codigo-pago':()=>import("@/components/Cpt"),
        Mapa
  },
  data() {
    return {
      titulo:' Nueva Solicitud Registro Abogada(o)', 
      paso: 0,
       requisitos:[
          {
              icon:'mdi-camera',
              titulo:'Fotografía digital 3x3 fondo BLANCO',
              subtitulo:'Fotografía actualizada, clara, de frente y sin lentes, en formato <b>JPG</b>, no debe ser mayor a 1 MB'
          },
          {
              icon:'mdi-camera',
              titulo:'Fotografía de firma idéntica al Carnet de Identidad de tamaño 3x6',
              subtitulo:'Firma en color azul y fondo blanco en formato <b>JPG</b>, no debe ser mayor a 1MB'
          },
          {
              icon:'mdi-file-pdf-box',
              titulo:'Título Profesional',
              subtitulo:'Título Profesional original escaneado a color en formato <b>PDF</b> (anverso y reverso)'
          }
          // {
          //     icon:'mdi-fingerprint',
          //     titulo:'Ciudadanía Digital',
          //     subtitulo:'Si no cuentas con Ciudadanía Digital presiona en: <a href="https://www.youtube.com/watch?v=EMFF0gXlcBM&list=PLJbHomsPk_rgSkY5k4gObXK79z_LopaS_" target="_blank"> ¿como obtener firma digital?</a>'
          // },
          // {
          //     icon:null,
          //     titulo:'O',
          //     subtitulo:''
          // },
          // {
          //     icon:'mdi-usb-flash-drive  ',
          //     titulo:'Frima Digital',
          //     subtitulo:'Si no cuentas con firma digital presiona en: <a href="https://www.youtube.com/watch?v=EMFF0gXlcBM&list=PLJbHomsPk_rgSkY5k4gObXK79z_LopaS_" target="_blank"> ¿como obtener firma digital?</a>'
          // }
      ],
      tipoMencion:[
        {value: 'PENAL', text: 'PENAL'},
        {value: 'CIVIL', text: 'CIVIL'},
        {value: 'FAMILIAR', text: 'FAMILIAR'},
        {value: 'ADMINISTRATIVO', text: 'ADMINISTRATIVO'},
        {value: 'CONSTITUCIONAL', text: 'CONSTITUCIONAL'},
        {value: 'PROCESAL', text: 'PROCESAL'},
        {value: 'TRIBUTARIO', text: 'TRIBUTARIO'},
        {value: 'COMERCIAL', text: 'COMERCIAL'},
        {value: 'MERCANTIL', text: 'MERCANTIL'},
        {value: 'LABORAL', text: 'LABORAL'},
        {value: 'SEGURIDAD SOCIAL', text: 'SEGURIDAD SOCIAL'},
        {value: 'AGRARIO', text: 'AGRARIO'},
        {value: 'INTERNACIONAL', text: 'INTERNACIONAL'},
        {value: 'OTROS', text: 'OTROS'},
      ],
      abogado: {
        id:null,
        universidadLicenciatura: null,
        lugarRegistro: null,
        numeroTitulo: null,
        fechaExpedicionTitulo: null,
        fotocopiaTituloLegalizada: false,
        mencion: null,
        fotoAbogado: null,
        firmaAbogado: null,
        tituloPdf: null
      },
      urlFoto:null,
      urlFirma:null,
      domicilio: {
        departamento: null,
        municipio:null,
        direccion: null,
        nro: null,
        telefono: null,
        longitud: -69.991,
        latitud: -16.41,
        zoom: null
      },
      tieneOficina:true,
      oficina: {
        departamento: null,
        municipio:null,
        direccion: null,
        nro: null,
        telefono: null,
        longitud: -69.991,
        latitud:  -16.41,
        zoom: null
      },
      documentos:null,
      urlDocumentos:null,
      documentoCompleto:null,
      valid: true,
      validDirecciones:true,
      validDocumento:true,
      loading:false,
      pregunta:'¿Está seguro de cancelar la solicitud?. se eliminaran los datos registrados',
      consulta:false, 
      token:null,
      dialogFirma:false,
      dialogAprobacion:false,
      overlay:false,
      mensaje:'',
      municipiosDomicilo:[],
      municipiosOficina:[],
      loadingConfirmacion:false
    };
  },
  async created(){
    await this.verificarRegistroCertificacion()
  },
  methods: {
    verificarRegistroCertificacion(){
      this.$http.get(`${this.$apiUrl}abogado-ciudadano?tipo=CERTIFICACION`).then(response=>{
        const data = response.data.data
        if(data){
          if(data.id){
            this.abogado.id=data.id
          }
        }
      })
    },
    getMunicipios(departamento, tipo){
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/dpas?idPadre=${departamento}&nivel=4`)
      .then(response=>{
        const data=response.data.data
          if(tipo==='domicilio'){
            this.municipiosDomicilo=data
          }else{
            this.municipiosOficina=data
          }
      }).catch(error=>{
          console.log(error)
      })
    },
    descargarArchivo(file,nombre) {
      const link = document.createElement("a");
          link.href = file;
          link.setAttribute("download",nombre);
          document.body.appendChild(link);
          link.click();
    },
    cambiarPosicionMapa(dep, tipo){
        if(tipo==='domicilio'){
          this.domicilio.latitud = dep.latitud
          this.domicilio.longitud = dep.longitud
        }else{
          this.oficina.latitud = dep.latitud
          this.oficina.longitud = dep.longitud
        }
      
    },
    // onFileChangeFoto(files){
    //     if(!files){
    //         return this.urlFoto=null
    //     }
    //     const file = files;
    //     this.urlFoto = URL.createObjectURL(file);  
    // },
    // onFileChangeFirma(files){
    //     if(!files){
    //         return this.urlFirma=null
    //     }
    //     const file = files;
    //     this.urlFirma = URL.createObjectURL(file);
        
    // },
    onFileChangeDocumentos(files){
        if(!files){
            return this.urlDocumentos=null
        }
        const file = files;
         if(file.size>6000000){
            this.$notify({
                group:'foo',
                type:'error',
                title:'ERROR DOCUMENTOS',
                text:'El documento no debe pesar mas de 6 MB'
            })            
            return; 
        }
        this.urlDocumentos = URL.createObjectURL(file);
        setTimeout(() => {
            this.showDocumento(this.urlDocumentos,'#previewDocumentosEscaneados',false);
        }, 100);   
    },
    validarFormularioGeneral(){
      if(this.$refs.formularioGeneral.validate()){
        this.paso++
      }
    },
    validarFormularioDirecciones(){
      if(this.$refs.formularioDirecciones.validate()){
        this.paso++
      }
    },
    firmarDocumento(){
        this.mensaje='Firmando Digitalmente'
        this.overlay=true
        this.dialogFirma= true
    },
    aprobarDocumento(){
      this.mensaje='Aprobando Documento Con Ciudadanía Digital'
      this.overlay=true
      this.dialogAprobacion=true
      this.pregunta='¿Está seguro de aprobar el documento con Ciudadanía Digital?. en caso de que acepte no podra realizar ninguna modificación'
      this.consulta=true
    },
    cancelarFirma(){
      this.mensaje='Firmando Digitalmente'
      this.overlay=false
      this.dialogFirma= false
    },
    aceptacionConfirmacion(){
      this.loadingConfirmacion=true
      if(this.dialogAprobacion){
        this.registroSolicitudNuevoAbogado()
      }else{
        this.cancelarRegistro()
      }
    },
    cancelarConfirmacion(){
      this.consulta = false
      this.pregunta = '¿Está seguro de cancelar la solicitud?. se eliminaran los datos registrados'
      this.dialogAprobacion = false
      this.overlay=false
    },
    showDocumento(pdf,id, base64) {
      const options = {
          height: "500px",
          pdfOpenParams: {
          pagemode: "thumbs",
          navpanes: 0,
          toolbar: 1,
          statusbar: 1,
          view: "FitH"
          }
      };
      if(base64){
        pdf='data:application/pdf;base64,'+pdf
      }
      PDFObject.embed(pdf, id, options);
    },
    registroSolicitudNuevoAbogado(pdf_firmado){
      this.mensaje='Registrando Solicitud Espere un momento ...'
      this.overlay=true
      this.dialogFirma=false
      if( this.dialogAprobacion){
        this.documentos=this.documentoCompleto
      }else{
         this.documentos=this.b64toBlob(pdf_firmado)
      }
      this.$http.post(`${this.$apiUrl}abogado-ciudadano/`,
        this.getFormSolicitud(), {
          headers: {
              "Content-Type": "multipart/form-data"
          } 
       }
      ).then(response=>{
        
        const data=response.data.data
          this.overlay = false
         // this.cpt = data.cpt
          if(data.aprobacionCiudadania){
            if(data.aprobacionCiudadania.linkAprobacion) {
              this.$notify({
                    group:'foo',
                    type:'success',
                    title:'APROBACION DOCUMENTOS',
                    text:'Debe aprobar los documentos de su solicitud con Ciudadanía Digital'
                })
               window.location.href = data.aprobacionCiudadania.linkAprobacion
            }
          }
          this.loadingConfirmacion=false
          this.paso++
          this.consulta = false    
      }).catch(error=>{
          this.loadingConfirmacion=false
          console.log(error)
      })
    },
    cancelarRegistro(){
        this.$router.push('/rpa')
    },
    cambiarProp(val) {
      this.abogado[val.campo] = val.valor;
    },
    enviarSolicitudNota(){
      if(this.$refs.formularioDocumento.validate()){
        this.loading=true
        this.$http.post(`${this.$apiUrl}abogado-ciudadano/nota-solicitud-registro`,
        this.getFormSolicitud(), {
            headers: {
                "Content-Type": "multipart/form-data"
            } 
        })
        .then(response=>{
            this.documentoCompleto=this.base64toBlob(response.data.data.pdf,'application/pdf')
            const url =window.URL.createObjectURL(this.documentoCompleto)
            setTimeout(() => {
              this.showDocumento(
                url,'#previewDocumentoMergeado',false);
                this.loading=false
                this.paso++
            }, 100);   
        }).catch(error=>{
          this.loading=false
          console.log(error)
        })
      }
    },
    getFormSolicitud(){
      let formData = new FormData();
      if (this.abogado.id){
        formData.append("idAbogado",this.abogado.id)
      }
      formData.append("universidadLicenciatura", this.abogado.universidadLicenciatura)
      formData.append("lugarRegistro", this.abogado.lugarRegistro)
      formData.append("numeroTitulo", this.abogado.numeroTitulo)
      formData.append("fechaExpedicionTitulo",this.abogado.fechaExpedicionTitulo)
      formData.append("fotocopiaTituloLegalizada",this.abogado.fotocopiaTituloLegalizada)
      formData.append("mencion",this.abogado.mencion)
      formData.append("fotoAbogado",this.abogado.fotoAbogado)
      formData.append("firmaAbogado",this.abogado.firmaAbogado)
      formData.append("documentos",this.documentos)
      formData.append("genero",this.abogado.genero)
      if(this.dialogAprobacion){
         formData.append("aprobacionCiudadania",true)
         formData.append("rutaCliente",this.$route.fullPath)
      }  
      formData.append("departamentoDomicilio",this.domicilio.departamento)
      formData.append("direccionDomicilio",this.domicilio.municipio.nombre+' / '+this.domicilio.direccion.toUpperCase())
      formData.append("nroDomicilio",this.domicilio.nro)
      formData.append("telefonoDomicilio",this.domicilio.telefono)
      formData.append("latitudDomicilio",this.domicilio.latitud)
      formData.append("longitudDomicilio",this.domicilio.longitud)
      if(this.tieneOficina){
        formData.append("tieneOficina",this.tieneOficina)
        formData.append("departamentoOficina",this.oficina.departamento)
        formData.append("direccionOficina",this.oficina.municipio.nombre+' / '+this.oficina.direccion.toUpperCase())
        formData.append("nroOficina",this.oficina.nro)
        formData.append("telefonoOficina",this.oficina.telefono)
        formData.append("latitudOficina",this.domicilio.latitud)
        formData.append("longitudOficina",this.domicilio.longitud)
      }
      return formData
    },
    changeFoto(data){
        this.abogado.fotoAbogado = data
    },
    changeFirma(data){
        this.abogado.firmaAbogado = data
    }
  }
};
</script>
<style>
.required label::after {
  content: " *";
  color: red;
}
.v-stepper__step--active + .auto-height .v-stepper__wrapper {
    height: auto !important;
}
</style>