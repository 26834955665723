export default{
    methods:{
        getRules(rules){
            var array=[]
            if (!rules) return array;
            const arules=rules.split(" ");
            arules.forEach(element => {
                if(element=='required')
                {
                array.push(v=>!!v||'Este campo es obligatorio');
                }
                if(element=='email')
                {
                array.push( v => /.+@.+/.test(v) || 'Correo no válido');
                }
                if(element=='requiredSelect'){
                array.push(v => (!!v) || "Debe elegir al menos una opción")
                }
                if(element=='direccion'){
                    array.push(v => /^.+\/.+\/.+/.test(v) || "Debes tener 3 datos separados por /")
                }
                if(element == 'foto'){
                    array.push(v => !v || v.size < 1000000 || 'El archivo no debe pasar 1 MB')
                }
                if(element == 'firma'){
                    array.push(v => !v || v.size < 6000000 || 'El archivo no debe pasar 6 MB')
                }
                if(element == 'file-abogado'){
                    array.push(v => !v || v.size < 6000000 || 'El archivo no debe pasar 6 MB')
                }
                if(element=='celular'){
                    const re = /^[0-9]/
                    array.push(v =>(re.test(String(v).toLowerCase()) && v.length === 8) || !v || 'Introduzca un numero válido.')
                }
            });
            array.push(v=>v!=this.value||'El valor debe ser distinto');
            return array;
        }
    }
}