<template>
  <section style="z-index:1 !important;">
    <l-map
      v-if="visible"
      :zoom="zoom"
      :center="[latitud, longitud]"
      style="min-height:300px;min-width:300px;z-index:1 !important;"
      id="mapa"
      GestureHandling
      :options="mapOptions"
      @click="changeMarker"
    >
      <l-control>
        <v-tooltip bottom>
           <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="mx-2"
                fab
                dark
                small
                color="primary"
                @click="getUserPosition"
              >
                <v-icon dark>
                  mdi-crosshairs-gps
                </v-icon>
              </v-btn>
           </template>
          <span>My Ubicacion</span>
        </v-tooltip>
      </l-control>

      <l-tile-layer
        :url="urlLeaflet"
        :attribution="attribution"
      >
      </l-tile-layer>

      <l-marker
        :lat-lng="[latitud, longitud]"
        ref="marker"
      >
        <l-popup ref="popup">
          <slot name="popup">
            <p class="text-subtitle2 text-weight-bold my-0">Ubicación</p>
          </slot>
        </l-popup>
      </l-marker>
    </l-map>
  </section>
</template>
<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControl
} from 'vue2-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

export default {
  props: [
    'latitud',
    'longitud'
  ],
  components: {
    LPopup,
    LMap,
    LTileLayer,
    LMarker,
    LControl
  },
  data () {
    return {
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false
      },
      urlLeaflet: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      latLong: [],
      visible: false
    }
  },
  beforeDestroy () {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  mounted () {
    setTimeout(() => {
      this.visible = true
    }, 300)
    setTimeout(() => {
      if (this.$refs.marker) {
        if (this.$refs.marker) {
          this.$refs.marker.mapObject.openPopup()
        }
      }
    }, 700)
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('../../assets/marker.png'),
      iconUrl: require('../../assets/marker.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      iconSize: [30, 40], // size of the icon
      iconAnchor: [15, 50], // point of the icon which will correspond to marker's location
      popupAnchor: [0, -50],
      shadowAnchor: [0, 0],
      shadowSize: [0, 0]
    })
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
    },
    geo_error () {
      this.model[this.propiedad].push(L.latLng([-16.503008, -68.131532]))
      this.visible = true
      this.$nextTick(() => {
        if (this.$refs.marker) {
          this.$refs.marker[0].mapObject.openPopup()
        }
      })
    },
    geo_success (position) {
      const lat = position.coords.latitude
      const long = position.coords.longitude
      if (this.model[this.propiedad].length === 0) {
        this.model[this.propiedad].push(L.latLng([lat, long]))
      }
      this.visible = true
      this.$nextTick(() => {
        if (this.$refs.marker) {
          if (this.$refs.marker[0]) {
            this.$refs.marker[0].mapObject.openPopup()
          }
        }
      })
    },
    async changeMarker (evt) {
      this.$emit('update:latitud', evt.latlng.lat)
      this.$emit('update:longitud', evt.latlng.lng)
      // const result = await fetch(
      //   `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${evt.latlng.lat}&lon=${evt.latlng.lng}`
      // );
      // if (result.status === 200) {
      //   const body = await result.json();
      // }
    },
    onMapClick (e) {
      alert('You clicked the map at ' + e.latlng)
    },
    getUserPosition() {
      if (navigator.geolocation) {
        // get GPS position
        navigator.geolocation.getCurrentPosition(pos => {
          // set the user location
          this.$emit('update:latitud',pos.coords.latitude)
          this.$emit('update:longitud',  pos.coords.longitude)
         
          // this.userLocation = {
          //   lat: ,
          //   lng:
          // };
        });
      }
    }
  }
}
</script>
